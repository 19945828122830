.App {
  text-align: center;
}

a {
  color: #ae73aa;
}
.center {
  width: 300px;
  overflow: hidden;
  text-align: center;
  margin-left: 75px;
}
.main-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color:#fff;
}

.purchase-table {
  width: 750px;
}

.purchase-table th {
  font-weight: 100;
}


.pay-btn {
  float: right;
}

.cancel-btn {
  float: left;
}

.content-container {
  color: #757575;
  background: #fff;
  padding: 1em;
  border-radius: 10px;
  max-width: 750px;
}
.content-container p {
  margin-bottom: 2px;
  text-transform: capitalize;
}
.disclaimer {
  margin-top: 1em;
  color: #9c9c9c;
  font-size: .75em;
}

.purchase-method {
  margin-top: 1em;
}

.dropdown-item-text {
  font-style: italic;
  font-weight: 500;
}
.dropdown{
  border-top: 2px solid #dee2e6;
  padding-top: 10px;
}
.btn-synkdPrimary {
  background-color: #774989;
  border-color: #774989;
  color: #ffffff;
}

.btn-synkdSecondary {
  background-color: #283e62;
  border-color: #283e62;
}

.btn-synkdPrimary:hover {
  color: #ffffff;
  background-color: #643e73;
}

.product-description {
  color: #ffffff;
  border-radius: 5px;
  padding: .5em;
  background: #282c34;
}

.error {
  color: #ffffff;
  border-radius: 5px;
  padding: .5em;
  background: #db3545;
}

.modal-title {
  margin-bottom: 2px;
  border-bottom: 2px solid #dee2e6;
  
  text-align: left;
  line-height: 1.5;
  font-size: 1rem;
  font-weight: bolder;
  color: #757575;
  font-family: Roboto,Helvetica,Arial,sans-serif;
  text-align: center;
}

.modal-header {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding: 1rem 1rem;
	border-top-left-radius: .3rem;
	border-top-right-radius: .3rem;
}

.product {
  color: #757575;
}

.product-name {
  border-top: 2px solid #dee2e6;
}
.product-price {
  border-bottom: 2px solid #dee2e6;
}
.main-container strong {
  color: #643e73;
}
.purchase-method h5 {
  color: #643e73;
}
.action-btns {
  position: relative;
  z-index: 9;
  float: right;
}
.date-package {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
	padding-top: 5px;
	padding-bottom: 5px;

  border-top: 2px solid #dee2e6;
  border-bottom: 2px solid #dee2e6;
}

.date-package p {
  flex: 0 50%;
}
.price-package {
  display: flex;
  flex-wrap: wrap;

  margin-top: 10px;
	padding-top: 15px;
  padding-bottom: 5px;
  
  width: 80%;
  margin: 0 auto;
}

.price-package p {
  flex: 0 50%;
}
.grand {
	margin-top: 10px;
	padding-top: 10px;
  padding-bottom: 10px;

}

.date-start, .date-end {
  color: #643e73;
}

#card-dropdown {
  width: 300px;
}

.topUp-purchase {
  display: flex;
  flex-direction: column;
}

.topUp-purchase h3 {
  margin-bottom: 2px;
  border-bottom: 2px solid #dee2e6;
  text-align: left;
  line-height: 1.5;
  font-size: 1.45rem;
  font-weight: bolder;
  color: #757575;
  font-family: Roboto,Helvetica,Arial,sans-serif;
  text-align: center;
}

.topUp-amount-price {
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
  padding-bottom: 5px;
  border-bottom: 2px solid #dee2e6;
}

.topUp-amount-price p {
  flex: 0 50%;
}

.topUp-total {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 2px solid #dee2e6;
  padding-top: 10px;
  padding-bottom: 10px;
}

.topUp-total p {
  flex: 0 50%;
}

.btn-promo{
  padding: .275rem .55rem; 
  margin-top: -4px;
  font-size: 0.9rem;
}

.invertfilter {
  filter: invert(1);
}